// Muli
/* muli-latin-400-normal*/
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/fontsource/muli-latin-400-normal.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/fontsource/muli-latin-400-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* muli-latin-400-italic*/
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/fontsource/muli-latin-400-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/fontsource/muli-latin-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
