//
// user.scss
//

// define variables if not yet defined in _users_variables.scss
$footer-bg: darken($body-bg, 10%) !default;

$accent: $primary !default;
$accent-hover: darken($primary, 10%) !default;

$i-ar-dark-bg: #1e235a !default;
$i-ar-dark-bg-selection: lighten($i-ar-dark-bg, 20%) !default;

$border-radius: 1rem !default;

$headings-font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !default;
$body-font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !default;
$body-font-size: 1rem !default;

/* ------------------------ */
/* Imperia Around Styles    */
/* ------------------------ */
:root {
  --#{$prefix}primary: #{$primary};
  --#{$prefix}secondary: #{$secondary};
  --#{$prefix}footer-bg: #{$footer-bg};
  --#{$prefix}accent: #{$accent};
  --#{$prefix}accent-hover: #{$accent-hover};
  --#{$prefix}i-ar-dark-bg: #{$i-ar-dark-bg};
  --#{$prefix}i-ar-dark-bg-selection: #{$i-ar-dark-bg-selection};

  --#{$prefix}border-radius: #{$border-radius};
  --#{$prefix}border-radius-sm: calc(var(--ar-border-radius) * 0.75);
  --#{$prefix}border-radius-lg: calc(var(--ar-border-radius) * 1.25);
  --#{$prefix}border-radius-xl: calc(var(--ar-border-radius) * 1.5);
  --#{$prefix}border-radius-xxl: calc(var(--ar-border-radius) * 2.25);

  --#{$prefix}headings-font-family: #{inspect($headings-font-family)};
  --#{$prefix}body-font-family: #{inspect($body-font-family)};
  --#{$prefix}body-font-size: #{$body-font-size};

  font-variant-ligatures: no-common-ligatures !important;
  font-feature-settings: 'liga' 0 !important;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}

/* @supports (font-variation-settings: normal) {
    :root { font-family: InterVariable, sans-serif; }
} */
html {
  scroll-behavior: smooth;
}

.footer {
  background-color: var(--#{$prefix}footer-bg);
}

@media screen and (max-width: 500px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    hyphens: auto;
    hyphenate-limit-chars: auto 5;
    hyphenate-limit-lines: 3;
  }
}
.rtl * {
  direction: rtl;
}
.text-body-secondary {
  opacity: 1;
  color: $gray-600 !important;
}
.text-as-list br {
  content: '&nbsp;' !important;
  line-height: 18px;
  height: 8px;
  display: block;
  width: 100%;
}
/* Logo */
.st0 {
  fill: #2d3787;
}
.btn-accent {
  color: var(--#{$prefix}primary);
  background-color: var(--#{$prefix}accent);
}
.btn-accent:hover {
  color: var(--#{$prefix}primary);
  background-color: var(--#{$prefix}accent-hover);
}
/* Startpage */
.startpage .navbar:not(.navbar-stuck) .i-ar-logo .st0 {
  fill: #fff;
}
.startpage .navbar:not(.navbar-stuck) .nav-link.nav-level-1,
.startpage .navbar:not(.navbar-stuck) .nav-link.language-toggle,
.startpage .navbar:not(.navbar-stuck) .navbar-toggler {
  color: #fff;
}
.startpage .navbar:not(.navbar-stuck) .navbar-toggler-icon,
.startpage .navbar:not(.navbar-stuck) .navbar-toggler-icon::before,
.startpage .navbar:not(.navbar-stuck) .navbar-toggler-icon::after {
  background-color: #fff;
}
@media screen and (max-width: 991px) {
  .startpage .navbar:not(.navbar-stuck) .nav-link.nav-level-1 {
    color: $gray-700;
  }
}
.i-ar-sliderimage-wrapper {
  min-height: 484px;
}
.i-ar-sliderimage-wrapper img {
  vertical-align: middle;
}
.i-ar-pl-6 {
  padding-left: 70px;
}
.i-ar-pr-6 {
  padding-right: 70px;
}
@media screen and (max-width: 1200px) {
  .i-ar-sliderimage-wrapper {
    min-height: 330px;
  }
  .i-ar-pl-6 {
    padding-left: 40px;
  }
  .i-ar-pr-6 {
    padding-right: 40px;
  }
}
@media screen and (max-width: 786px) {
  .i-ar-pl-6 {
    padding-left: 10px;
  }
  .i-ar-pr-6 {
    padding-right: 10px;
  }
  .i-ar-sliderimage-wrapper {
    min-height: 280px;
  }
}
@media screen and (max-width: 600px) {
  .i-ar-pl-6 {
    padding-left: 0px;
  }
  .i-ar-pr-6 {
    padding-right: 0px;
  }
  .i-ar-sliderimage-wrapper {
    min-height: 230px;
  }
}
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
.page-wrapper {
  padding-top: 70px;
}
.i-ar-logo img {
  margin-top: -10px;
  width: 150px;
}
.i-ar-grayscale {
  filter: grayscale(100%);
  opacity: 0.7;
}
.i-ar-remove-grayscale-onhover:hover {
  filter: grayscale(0%);
  opacity: 1;
}
img.i-ar-hero-image {
  border-radius: 20px 150px 20px 150px;
  max-width: 600px;
}
@media screen and (max-width: 767px) {
  img.i-ar-hero-image {
    border-radius: 20px;
    max-width: 100%;
  }
}
.wordmodule ol {
  list-style-type: none;
  counter-reset: li;
  color: $gray-700;
  padding-left: 0;
}
.wordmodule ol li {
  position: relative;
  padding-left: 30px;

  margin-bottom: 1rem;
}
.wordmodule ol li strong {
  display: block;
  color: #121519;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0.8rem;
}
.wordmodule ol li strong + br {
  line-height: 1px;
  height: 1px;
  display: none;
}
.wordmodule ol li:before {
  counter-increment: li;
  content: counter(li, decimal-leading-zero);
  color: $gray-600;
  margin-right: 0.25em;
  position: absolute;
  font-size: 16px;
  top: 0px;
  left: 0;
}
.wordmodule ul {
  list-style: none;
}
.wordmodule ul li {
  position: relative;
  margin-bottom: 10px;
}
.wordmodule ul li:before {
  content: '✓';
  position: absolute;
  left: -20px;
  top: 0px;
}
.wordmodule h2 {
  padding-bottom: 24px;
  font-size: 40px;
}

.pina-text p {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 18px;
}
.pina-text h2 {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 24px;
  padding-top: 48px;
}
.pina-text h3 {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  padding-top: 40px;
}
.pina-text ol li,
.pina-text ul li {
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
}

/* Default Wordmodule Formatting */
/* Unordered list */
.i-ar-default-wordmodule-format ul {
  list-style: none;
}
.i-ar-default-wordmodule-format ul li {
  position: relative;
  margin-bottom: 10px;
}
.i-ar-default-wordmodule-format ul li:before {
  content: '✓';
  position: absolute;
  left: -20px;
  top: 0px;
}
.i-ar-default-wordmodule-format h2 {
  padding-bottom: 24px;
  font-size: 40px;
}
/* Article: Alignment of quote, image and text */

.pina-image-wrapper img {
  width: 100%;
}

.pina-image-left {
  float: left;
  margin: 0 1.3rem 0.5rem 0;
  max-width: 300px;
  margin-left: -2.5rem;
}

.pina-image-right {
  float: right;
  margin: 0 0 0.5rem 1.3rem;
  max-width: 300px;
  margin-right: -2.5rem;
}

.pina-image-center {
  text-align: center;
  margin: 1em auto;
  max-width: 800px;
}
.pina-image-center .figure-caption {
  text-align: left;
}
.pina-text:after,
.clear-float {
  content: '';
  display: table;
  height: 1px;
  clear: both;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .pina-image-left,
  .pina-image-right {
    margin: 10px auto;
    float: none;
    display: block;
  }
}
@media screen and (max-width: 300px) {
  .pina-image-left,
  .pina-image-right {
    max-width: 100%;
  }
}

.pina-quote-left {
  float: left;
  margin: 0 1.3rem 0.5rem 0;
  max-width: 300px;
  margin-left: -2.5rem;
}

.pina-quote-right {
  float: right;
  margin: 0 0 0.5rem 1.3rem;
  max-width: 300px;
  margin-right: -2.5rem;
}

.pina-quote-center {
  text-align: left;
  margin: 1em auto;
  max-width: 800px;
}

.pina-quote-center .pina-quote {
  background-position-y: center;
  background-size: 20%;
  margin-left: 1rem;
  padding: 2rem 1rem 2rem 12rem;
}

.pina-quote:after,
.clear-float {
  content: '';
  display: table;
  height: 1px;
  clear: both;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .pina-quote-left,
  .pina-quote-right {
    margin: 10px auto;
    float: none;
    display: block;
  }
}
@media screen and (max-width: 300px) {
  .pina-quote-left,
  .pina-quote-right {
    max-width: 100%;
  }
}
/* Card Layouts */
.card-bg-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position: center;
  min-height: 220px;
}
.card-bg-img:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 0;
  top: 0;
  left:0;
  border-radius: 2.2rem;
}
.card-bg-img .card-body {
  z-index: 2;
}
.card-img-small {
  width: 100px;
  height: 100px;
}
/* Youtube Video */

div.yt-video-container {
  margin: auto;
}
div.yt-video-preview {
  margin: auto;
  outline: none;
  background: #000 no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  position: relative;
}
div.yt-video-preview.big_16_9,
div.yt-video-container.big_16_9 {
  width: 860px;
  height: 484px;
}
div.yt-video-preview.big_4_3,
div.yt-video-container.big_4_3 {
  width: 860px;
  height: 645px;
}
div.yt-video-preview.small_16_9,
div.yt-video-container.small_16_9 {
  width: 640px;
  height: 480px;
}
div.yt-video-preview.small_4_3,
div.yt-video-container.small_4_3 {
  width: 640px;
  height: 360px;
}

button.yt-start-video {
  background: red no-repeat;
  background-size: 30%;
  background-position: 55% 50%;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' fill='%23fff'%3e%3cpath d='M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z'/%3e%3c/svg%3e");
  color: #fff;
  width: 80px;
  height: 80px;
  border: 2px solid #fff;
  border-radius: 40px;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}
.yt-start-notice {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  position: absolute;
  top: 0px;
  text-align: center;
  padding: 10px 5px;
  font-size: 20px;
}
.yt-start-notice a {
  color: $secondary;
}
iframe.big_16_9,
iframe.small_16_9 {
  aspect-ratio: 16 / 9 !important;
  width: 100% !important;
}
iframe.big_4_3,
iframe.small_4_3 {
  aspect-ratio: 4 / 3 !important;
  width: 100% !important;
}
@media screen and (max-width: 1100px) {
  div.yt-video-preview,
  div.yt-video-container {
    width: 100% !important;
  }
}
.lang-menu-sm a.active {
  color: var(--#{$prefix}primary);
  pointer-events: none;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
  padding: 20px;
}

/* .paralax .ai-marker-wrapper, .ai-marker-wrapper {
    pointer-events: auto !important;
}
*/

.i-ar-logo-swiper a,
.i-ar-logo-swiper a:hover {
  color: $gray-800;
  text-decoration: none;
}

input#trackVisits {
  margin-right: 7px;
  vertical-align: middle;
}

/* Navigation item News Fix */
.nav-item:not(.dropdown) > a.nav-level-1 {
  pointer-events: auto !important;
  cursor: pointer !important;
}

#eventslist a {
  text-decoration: none;
}
#eventslist a p {
  color: $gray-900;
}
// Visitenkarte
.hyphens-card {
  h3,
  p {
    word-wrap: break-word;
    hyphens: auto;
    hyphenate-limit-chars: auto 5;
    hyphenate-limit-lines: 3;
  }
}

/* FullCalendar Styles */
.imperia-fc-container .fc-view-harness {
  height: auto;
  overflow: hidden;
}
.imperia-fc-container .fc-scroller {
  height: auto !important;
}
.imperia-fc-container .fc-event {
  border-radius: 30px;
  width: 30px;
  line-height: 26px;
  padding: 0;
  height: 30px;
  text-align: center;
  cursor: pointer !important;
}
.imperia-fc-container a {
  text-decoration: none !important;
}

.imperia-fc-container .fc-toolbar-title {
  font-size: 1.3rem !important;
}
.image_gallery_container .card {
  background-color: transparent !important;
}
@media screen and (max-width: 530px) {
  .gallery_badge {
    font-size: 12px !important;
    margin: 4px !important;
    padding: 7px 12px !important;
    hyphens: auto;
    hyphenate-limit-chars: 12 6 8;
    word-wrap: break-word;
  }
}

/* Scrollbar in tabs */
.nav-tabs::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.nav-tabs::-webkit-scrollbar-track {
  box-shadow: none;
  background: rgba(123, 123, 123, 0.2);
  border-radius: 10px;
}
.nav-tabs::-webkit-scrollbar-thumb {
  background-color: rgba(59, 59, 61, 0.2);
  border-radius: 10px;
}

// Dark background sections
.i-ar-dark-bg {
  background-color: var(--#{$prefix}i-ar-dark-bg);
  color: #fff;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    color: #fff;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    color: #fff;
    border-color: #fff;
  }
  .nav-tabs .nav-link,
  .nav-tabs .nav-link {
    color: #fff;
  }

  .nav-tabs::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .nav-tabs::-webkit-scrollbar-track {
    box-shadow: none;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  .nav-tabs::-webkit-scrollbar-thumb {
    background-color: rgba(147, 152, 215, 0.4);
    border-radius: 10px;
  }
  .btn-link {
    color: var(--#{$prefix}accent);
  }
  .btn-link:hover {
    color: var(--#{$prefix}accent-hover);
  }
  ::-moz-selection {
    background-color: var(--#{$prefix}i-ar-dark-bg-selection);
  }
  ::selection {
    background-color: var(--#{$prefix}i-ar-dark-bg-selection);
  }
  ::-moz-selection {
    background: var(--#{$prefix}i-ar-dark-bg-selection);
  }
}

// Startpage Video

.hero.hero-video {
  height: 335px;
  position: relative;
}
.hero .hero-slide-video {
  z-index: -1;
  width: 100%;
  height: 335px;
  position: fixed;
}
.hero .hero-slide-video .video-container {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  overflow-clip-margin: content-box;
  overflow: clip;
}

@media print, screen and (min-width: 40em) {
  .hero.hero-video {
    height: 70vh;
    max-height: 500px;
  }
  .hero .hero-slide-video {
    height: 70vh;
    max-height: 500px;
  }
}

@media print, screen and (min-width: 64em) {
  .hero.hero-video {
    max-height: none;
    height: calc(100vh - 50px);
  }
  .hero .hero-slide-video {
    max-height: none;
    height: calc(100vh - 50px);
  }
}
